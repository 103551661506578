@import '../util.scss';
@import '../variables.scss';

.project-whitelist {
  height: 100%;
  
  &.disabled {
    pointer-events: none;
    
    .content-col {
      opacity: .5;
      filter: blur(1px);
    }
  }
  
  .wl-content-row {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .icon-col {
    font-size: 3rem;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $alt-transparent;
    flex: none;
    
    display: flex;
    align-items: center;
    
    .fa {
      color: $brand-danger;
    }
    h2 {
      margin-left: 10px;
      font-weight: normal;
    }
  }
  
  .content-col {
    flex: 1 0 0;
    padding-top: 1rem;
  
    display: flex;
    flex-direction: column;
  
    .wl-tree-cont {
      flex: none;
      max-height: 35%;
      overflow: auto;
    }
  
    .wl-details-cont {
      flex: auto;
      height: 100%;
      min-height: 0;
      overflow: auto;
    }
  }
  @include media-breakpoint-up(lg) {
    .icon-col {
      font-size: 5rem;
      border-bottom: none;
      margin-bottom: 30px;
      flex-direction: column;
      align-items: center;
  
      .svg-icon {
        height: 6rem;
      }
    }
    .content-col {
      position: relative;
      top: auto;
      flex: 0 0 100%;
      padding-left: .5rem;
      padding-top: 0;
      border-left: 1px solid $alt-transparent;
  
      flex-direction: row;
      
      .wl-col-divider {
        padding-right: .5rem;
        border-right: 1px solid $alt-transparent;
        margin-right: 1rem;
      }
  
      .wl-tree-cont {
        max-height: none;
        height: 100%;
        width: 300px;
      }
    }
  }
  
  .wl-disabled-warning {
    position: absolute;
    top: 60px;
    left: 0; right: 0;
    padding: 4px 1rem;
    border-radius: 2px;
    font-size: 90%;
    color: $brand-primary;
    background: $warn-bg;
    z-index: 1;
  }
  @include media-breakpoint-up(lg) {
    .wl-disabled-warning {
      top: -30px;
    }
  }
}
@include media-breakpoint-up(lg) {
  .project-whitelist {
    padding-top: 30px;
  }
}
