@import '../util.scss';
@import '../variables.scss';

.collaborator-table {
  td {
    @include text-ellipsis();
    
    &.td-email {
      width: 60%;
    }
    
    &.td-delete {
      width: 2.5rem;
      &:not(.disabled) {
        cursor: pointer;
      }
    }
    
    .self-note {
      color: $gray-light;
      font-size: 90%;
    }
  }
}
