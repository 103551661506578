@import '../util.scss';
@import '../variables.scss';

.project-details {
  $icon-sz: 10rem;
  $icon-sz-sm: 4rem;
  
  .project-icon {
    margin: 20px 0 0;
    color: #fff;
    padding-bottom: 1rem;
    border-bottom: 1px solid $alt-transparent;
    align-self: flex-start;
    display: flex;
    align-items: center;
  
    img {
      height: $icon-sz-sm;
      margin: 0 .5rem 0 -1rem;
    }
  
    .project-stage-label {
      font-size: 2rem;
      flex-grow: 1;
    }
  }
  @include media-breakpoint-up(md) {
    .project-icon {
      border-bottom: 0;
      flex-direction: column;
      
      img {
        height: $icon-sz;
        margin: 0;
      }
    }
  }
  
  .project-link {
    align-self: flex-end;
    font-size: 15px;
  
    &.settings {
      position: absolute;
      right: 0;
      top: -4.4rem;
      .fa {
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
    
    .svg-icon, .fa {
      padding-left: 12px;
      margin-left: 12px;
      border-left: 1px solid;
    }
  }
  @include media-breakpoint-up(md) {
    .project-link {
      &.settings {
        right: 1rem;
        top: 20px; // align with page icon top margin
        .fa {
          padding-left: 12px;
          margin-left: 12px;
          border-left: 1px solid;
        }
      }
    }
  }
  
  .toggler-cont {
    position: absolute;
    right: 0;
    top: 6rem;
    min-width: 80px;
    min-height: 40px;
    @include center-flex();
    flex-direction: column;
    z-index: 1;
    
    .gripper {
      background-color: #fff !important;
    }
    .toggler-label {
      font-size: .7rem;
      color: $alt-transparent;
    }
  }
  @include media-breakpoint-up(md) {
    .toggler-cont {
      position: static;
    }
  }
  
  .project-summary {
    padding: 0 2rem;
  }
  
  .project-checkboxes {
    margin-top: 100px;
    color: #fff;
    
    & > .disabled {
      color: $alt-transparent;
    }
    
    .custom-checkbox {
      margin: 0 0 5px 25px;
      @include text-truncate();
    }
  }
  
  .project-status {
    min-height: 200px;
    color: $alt-transparent;
    text-align: center;
    
    @include center-flex();
    flex-direction: column;
    align-items: stretch;
    
    .button-cont {
      @include center-flex();
  
      .download-btn {
        min-width: 200px;
        display: flex;
        align-items: center;
    
        .btn-content {
          flex: 1 1 auto;
          padding-right: 1rem;
          border-right: 1px solid;
        }
        .fa {
          flex: 0 0 2rem;
          font-size: 2rem;
          padding-left: 10px;
          width: 2rem;
        }
      }
    }
  }
  
  .status-list {
    padding: 0;
    margin: 0;
    list-style: none;
    
    & > li {
      margin-bottom: .5rem;
      border-bottom: 1px solid;
      
      display: flex;
      align-items: flex-end;
      
      .status-list-label {
        flex: 1 0 auto;
        display: inline-block;
        position: relative;
        top: 5px;
        padding-right: 10px;
        background: $main-bg;
      }
      .status-list-value {
        flex: 100 1 auto;
        color: $white-base;
        text-align: left;
        @include text-truncate();
      }
    }
  }
  
  @include media-breakpoint-up(md) {
    .project-summary {
      padding: 0;
    }
    .project-icon {
      border-right: 1px solid $alt-transparent;
    }
    .project-status {
      min-height: 300px;
    }
  }
  
  @include media-breakpoint-up(lg) {
    .project-status {
      text-align: left;
    }
  }
}

.policy-card {
  min-height: 200px;
  margin-bottom: 15px;
  background: transparent;
  color: $alt-color;
  border-color: $card-disabled-bg;
  display: flex;
  flex-direction: row; // bs4 override
  align-items: stretch;
  
  &.disabled {
    .policy-card-right {
      background: $card-disabled-bg;
      cursor: not-allowed;
    }
  }
  &.muted {
    opacity: .7;
    cursor: not-allowed;
    
    .toggler {
      pointer-events: none;
    }
    
    button, [role="button"] {
      cursor: not-allowed;
    }
  }
  
  .policy-card-left {
    flex: 0 0 45%;
    padding: 20px 10px 35px;
    background: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    .card-title {
      font-weight: normal;
    }
    
    .policy-icon {
      margin-bottom: 10px;
      color: $brand-danger;
    }
  }
  
  .policy-card-right {
    flex: 1 1 auto;
    padding: 50px 20px 30px 10px;
    background: #fff;
    border-left: 1px solid $card-disabled-bg;
    transition: background .3s;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    
    .card-text {
      align-self: stretch;
      margin: 0 0 0 1rem;
      padding: .5rem 0;
      border-top: 1px solid $card-disabled-bg;
      border-bottom: 1px solid $card-disabled-bg;
      line-height: 1.2rem;
      color: $brand-danger;
    }
  }
}
