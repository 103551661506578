@import '../util.scss';
@import '../variables.scss';

.btn-prompt-cont {

  &.show-prompt {
    .btn-prompt {
      opacity: .95;
      transform: scale3d(1, 1, 1);
    }
    .main-btn {
      opacity: 0;
      pointer-events: none;
    }
  }

  .main-btn {
    min-width: 2.5rem;
    opacity: 1;
    transition: opacity .1s;
  
    padding: 0; // give all spacing to icon for tooltip is displayed from it
    span[data-tooltip] {
      padding: .5rem;
      line-height: 2rem;
    }
  }

  .btn-prompt {
    margin: 0 -4px;
    padding: 5px .5rem;
    display: flex;
    align-items: center;
    border-radius: 2px;
    color: #fff;
    background: $alert-bg-warning;
    opacity: 0;
    transition: transform .2s cubic-bezier(.41, .92, .67, 1);
    transform: scale3d(.3, .3, .3);

    .prompt-text {
      font-family: 'HelveticaNeue-Lite';
      font-size: 13px;
      line-height: 1.3;
    }

    .btn {
      margin-left: .5rem;
    }
    .close {
      align-self: flex-start;
      margin-left: 1rem;
    }
  }
}
