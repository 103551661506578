@import '../../variables.scss';

.file-tree {
  display: inline-block; /* replacer for width: min-content;*/
  list-style: none;
  padding: 1rem;
  margin: 0;
  
  .node {
    margin-bottom: .25rem;
    
    &.selected > .node-controls {
      background: rgba(0, 0, 0, .3);
    }
  }
  
  .node-controls {
    display: flex;
    
    .checkbox-label {
      margin: 0 .5rem;
    }
  
    [type="checkbox"] {
      vertical-align: middle;
    }
  }
  
  .node-label {
    margin-right: .5rem;
    color: $white-base;
    cursor: default;
    user-select: none;
  }
  
  .node-icon {
    color: $brand-danger;
  }
  .node-meta {
    color: $alt-transparent;
    cursor: default;
  }
  
  .node-badge {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    vertical-align: text-bottom;
    margin-left: 5px;
    cursor: default;
  }
  
  .node-toggler {
    > .checkbox {
      opacity: 0;
      height: 0;
    }
  }
}

