@import '../util.scss';
@import '../variables.scss';

.project-list-container {
  
  &:not(.empty-list) {
    @include media-breakpoint-down(xs) {
      .project-item.hollow {
        display: none;
      }
      .btn-round.hanging {
        display: block;
      }
    }
  }
  
  $btn-round-sz: 60px;
  .btn-round {
    position: absolute;
    width: $btn-round-sz;
    height: $btn-round-sz;
    bottom: -$btn-round-sz/2;
    right: -$btn-round-sz/2;
    border-radius: 50%;
    padding: 20px;
    @include center-flex();
    
    &.hanging {
      position: fixed;
      display: none;
      bottom: 1rem; right: 1rem;
      box-shadow: -1px 1px 4px 1px rgba(41, 34, 92, 0.1);
    }
  }
}

.project-list {
  padding-top: 30px;
  
  .project-item {
    position: relative;
    min-height: 160px;
    margin: 0 20px 40px 20px;
    padding: 2rem 1rem;
    color: $blue-darker;
    @include center-flex();
    flex-direction: column;
    align-items: stretch;
    
    &:not(.hollow):hover {
      background: rgba(255, 255, 255, .9);
    }
    
    &.hollow {
      text-align: center;
      background: transparent;
      border: 3px dashed #fff;
      
      .project-new-title {
        color: #fff;
        margin: 0;
      }
    }
    
    .close {
      position: absolute;
      right: 15px;
      top: 10px;
    }
    &.no-delete .close {
      cursor: not-allowed;
    }
    
    .card-title {
      color: $brand-danger;
      font-weight: normal;
      margin: 0 10px 10px 0;
      text-align: center;
      word-break: break-all;
    }
    .card-text {
      margin-bottom: 0;
      text-align: center;
    }
  }
  
  @include media-breakpoint-up(lg) {
    .project-item {
      margin: 0 15px 50px 15px;
    }
  }
}

.collaborator-list {
  position: absolute;
  bottom: 3px; left: .5rem;
  display: flex;
  flex-wrap: wrap;
  
  .collaborator-item {
    font-size: 12px;
    margin: 0 5px 5px 0;
    width: 20px; height: 20px; // + borders
    border: 2px solid;
    border-radius: 50%;
    overflow: hidden;
    color: #8c689b;
    
    &.owner {
      color: rgba(236, 0, 140, .7);
    }
    
    div {
      line-height: 1rem;
      width: 1rem;
      text-align: center;
    }
  }
}
