@import '../../util.scss';
@import '../../variables.scss';

.attack-list-pager {
  padding: .5rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 90%;
  
  & > * {
    margin-left: 1rem;
  };
  
  .btn {
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    & + .btn {
      margin-left: .5rem;
    }
    
    &.disabled {
      opacity: .4;
    }
    
    &:focus {
      box-shadow: none;
    }
  }
}
