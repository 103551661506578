@import '../variables.scss';

.loader {
  height: 3px;
  width: 100%;
  position: relative;
  overflow: hidden;
  
  &:before {
    content: "";
    position: absolute;
    left: -200px;
    width: 200px;
    height: 100%;
    will-change: left, width;
    animation: loading 1.5s linear infinite;
  }
  &.color-white:before {
    background-color: $alt-transparent;
  }
  &.color-pink:before {
    background-color: $brand-danger;
  }
}
@keyframes loading {
  from { left: -200px; width: 30%; }
  50% { width: 30%; }
  70% { width: 70%; }
  80% { left: 50%; }
  95% { left: 120%; }
  to { left: 100%; }
}
