@import '../util.scss';
@import '../variables.scss';

.project-in-memory {
  .icon-col {
    font-size: 3rem;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid $alt-transparent;
    
    display: flex;
    align-items: center;
    
    h2 {
      margin-left: 10px;
      font-weight: normal;
    }
  }
  @include media-breakpoint-up(lg) {
    .icon-col {
      font-size: 5rem;
      border-bottom: none;
      flex-direction: column;
      align-items: center;
  
      .svg-icon {
        height: 6rem;
      }
    }
    .content-col {
      padding-left: 2rem;
      border-left: 1px solid $alt-transparent;
    }
  }
}
@include media-breakpoint-up(lg) {
  .project-in-memory {
    padding-top: 30px;
  }
}
