@import '../util.scss';
@import '../variables.scss';

.alert {
  position: absolute!important; //override bs4
  top: 20px;
  left: 1rem; right: 1rem;
  margin: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  
  span {
    opacity: .8;
  }
  strong {
    font-weight: normal;
    opacity: 1;
  }
  
  &.alert-dismissible .close {
    top: 0; // override bs4
    padding: 1px .5rem;
  }
}
@include media-breakpoint-up(sm) {
  .alert {
    top: 6px;
    left: 6rem; right: 6rem;
  }
}
