@import '../util.scss';
@import '../variables.scss';

.main-container {
  height: 100%;
  overflow-y: auto;
}

.info-bar-row {
  position: fixed;
  right: 0;left: 0;
  top: $navbar-h-xs;
  height: $infobar-h-xs;
  background: $info-bar-bg;
  z-index: 1029;
  
  .info-bar-content {
    display: none;
    line-height: 3rem;
    
    &.info-bar-title {
      padding: 0 3rem;
      color: $info-bar-color;
    }
    &.info-bar-link {
      margin-left: 1rem;
      color: #fff;
  
      .back-icon {
        color: $brand-danger;
      }
    }
  }
}
@include media-breakpoint-up(sm) {
  .info-bar-row {
    top: $navbar-h;
    height: $infobar-h;
  
    .info-bar-content {
      display: inline-block;
    }
  }
}
