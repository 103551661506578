@import '../util.scss';
@import '../variables.scss';

.action-center {
  position: fixed;
  right: 0;
  bottom: 0;
  top: $top-h-xs;
  width: 350px;
  background: $action-center-bg;
  overflow: hidden;
  z-index: 1031;
  transition: transform .3s;
  transform: translateX(100%);
  
  &.page {
    width: 100%;
    z-index: 0;
  }
  
  &.open {
    transform: translateX(0);
  }
  
  $ac-header-h: 4;
  .ac-header {
    height: #{$ac-header-h}rem;
    padding: 30px 30px 12px;
    display: flex;
    justify-content: space-between;
    
    .ac-header-link {
      cursor: pointer;
    }
  }
  
  .ac-body {
    height: calc(100% - #{$ac-header-h}rem);
    padding-bottom: 20px;
  }
  
  // Separators
  .ac-header,
  .ac-item-group-title:not(:first-child) {
    position: relative;
    
    // A separator line
    &:after {
      content: "";
      position: absolute;
      right: 30px; left: 30px;
      top: 0;
      border-bottom: 1px solid rgba(255, 255, 255, .2);
    }
  }
  .ac-header:after {
    top: auto; //override above
    bottom: 0;
  }
  
  .ac-list {
    position: absolute;
    top: 4rem;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
  }
  .ac-item-group-title {
    padding: 15px 30px 5px;
    color: $brand-danger;
    @include text-ellipsis();
  }
  .ac-item {
    position: relative;
    overflow: hidden; //has floats
    padding: 15px 30px;
    
    //animation
    &.ac-animate-enter {
      opacity: 0.01;
      transform: translateX(100%) scaleY(.5);
      &.ac-animate-enter-active {
        opacity: 1;
        transform: translateX(0) scaleY(1);
        transition: all 200ms;
      }
    }
    &.ac-animate-exit {
      opacity: 1;
      transform: translateX(0) scaleY(1);
      &.ac-animate-exit-active {
        opacity: 0.01;
        transform: translateX(100%) scaleY(.5);
        transition: all 200ms ease-out;
      }
    }
    
    &.unread {
      box-shadow: inset -5px 0 0 0 $brand-danger;
    }
    &:hover {
      background: rgba(55, 53, 70, 0.65);
    }
    
    .ac-item-title, .ac-em {
      color: $alt-transparent;
    }
    .ac-item-date {
      margin-top: 10px;
      color: $alt-transparent;
    }
    
    .svg-icon {
      float: left;
      opacity: .7;
      margin: -.5rem 5px -.5rem -.5rem;
    }
    
    p {
      font-size: .8rem;
      margin-bottom: 0;
    }
    
    .close {
      margin: -10px -15px 0 0;
      color: $gray-lighter;
      text-shadow: none;
    }
  }
  
  .ac-link {
    float: right;
    padding: 2px 5px;
    color: $white-base;
    border-bottom: 1px solid $brand-danger;
    font-size: 90%;
    
    &:focus {
      text-decoration: none;
    }
  }
}
@include media-breakpoint-up(sm) {
  .action-center {
    top: $top-h;
  }
}

// Bar
.action-center-bar {
  position: absolute;
  top: 0; bottom: 0;
  right: 0;
  width: 4.2rem;
  color: $action-center-bg;
  cursor: pointer;
  transition: all .3s;
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  &.open {
    background: #000;
    color: $white-base;
  }
  
  .svg-icon {
    margin-top: .5rem;
    padding-left: .5rem;
    border-left: 1px solid $alt-transparent;
  }
}

// Balloon
//bs4
.popover {
  cursor: pointer;
  
  // hide original arrow due to issues and show ours
  .arrow {
    display: none;
  }
  .popover-inner {
    position: relative;
    &:after,
    &:before {
      content: "";
      position: absolute;
      top: -12px; right: 8px;
      width: 0; height: 0;
      border: 11px solid transparent;
      border-top-width: 0;
      border-bottom-color: rgba(0, 0, 0, .1);
    }
    &:after {
      top: -10px; right: 9px;
      border-bottom-color: $white-base;
    }
  }
  
  .popover-body {
    padding: 9px 14px;
    line-height: 1.2;
    
    .svg-icon {
      float: left;
      margin: -5px 5px 5px -5px;
    }
  }
}
// Hide balloon for xs screens as AcBar is hidden too for them
@include media-breakpoint-down(xs) {
  .popover {
    display: none !important;
  }
}
