@import '../../util.scss';
@import '../../variables.scss';

.attack-filter {
  flex: none;
  margin-bottom: .5rem;
  
  .attack-filter-bar {
    padding-bottom: 1rem;
    border-bottom: 1px solid $widget-bg-active;
    display: flex;
    
    .attack-filter-toggler {
      padding: .5rem;
      max-width: calc(100% - 12rem);
      display: flex;
      align-items: baseline;
      
      &:hover {
        background: $widget-bg-active;
      }
      
      &.active {
        background: $widget-bg-active;
        border-radius: 3px 3px 0 0;
        box-shadow: 0 1rem 0 0 $widget-bg-active;
      }
      
      .chevron {
        align-self: center;
      }
      
      .aft-long-val {
        display: inline-block;
        max-width: 100%;
        margin: 0 4px;
        font-size: 90%;
      }
    }
    
    .attack-filter-add-remove {
      display: flex;
      justify-content: center;
    }
    
    .btn {
      margin-left: auto;
    }
    
    .nav-link {
      align-self: center;
      padding: 0 1rem;
    }
  }
  
  .attack-filter-editor {
    display: none;
    padding: 1rem;
    border-radius: 0 3px 3px 3px;
    background: $widget-bg-active;
    font-size: 90%;
    
    &.open {
      display: block;
    }
    
    .afe-item {
      display: flex;
      margin-bottom: 1rem;
      
      .afe-item-label {
        flex: 0 0 6rem;
      }
      
      .afe-item-control {
        flex: 1 1 auto;
        
        .form-check {
          margin-bottom: 0;
        }
      }
    }
    
    .btn {
      margin-left: 1rem;
    }
  }
}
