@import "./util.scss";

// Font Awseme icon font
//$fa-font-path: "//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts";
$fa-font-path: "/public/fonts/font-awesome";
@import "~font-awesome/scss/font-awesome";

// HelveticaNeue font
@font-face {
  font-family: 'HelveticaNeue-Lite';
  src: url('/public/fonts/helvetica-neue/30C2B1_0_0.eot');
  src: url('/public/fonts/helvetica-neue/30C2B1_0_0.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/helvetica-neue/30C2B1_0_0.woff2') format('woff2'),
      url('/public/fonts/helvetica-neue/30C2B1_0_0.woff') format('woff'),
      url('/public/fonts/helvetica-neue/30C2B1_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url('/public/fonts/helvetica-neue/30C2B1_1_0.eot');
  src: url('/public/fonts/helvetica-neue/30C2B1_1_0.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/helvetica-neue/30C2B1_1_0.woff2') format('woff2'),
      url('/public/fonts/helvetica-neue/30C2B1_1_0.woff') format('woff'),
      url('/public/fonts/helvetica-neue/30C2B1_1_0.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeue-Roman';
  src: url('/public/fonts/helvetica-neue/30C2B1_2_0.eot');
  src: url('/public/fonts/helvetica-neue/30C2B1_2_0.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/helvetica-neue/30C2B1_2_0.woff2') format('woff2'),
      url('/public/fonts/helvetica-neue/30C2B1_2_0.woff') format('woff'),
      url('/public/fonts/helvetica-neue/30C2B1_2_0.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeue-Middle';
  src: url('/public/fonts/helvetica-neue/30C2B1_3_0.eot');
  src: url('/public/fonts/helvetica-neue/30C2B1_3_0.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/helvetica-neue/30C2B1_3_0.woff2') format('woff2'),
      url('/public/fonts/helvetica-neue/30C2B1_3_0.woff') format('woff'),
      url('/public/fonts/helvetica-neue/30C2B1_3_0.ttf') format('truetype');
}

body {
  font-family: 'HelveticaNeue-Roman', sans-serif;
}

.font-90p { font-size: 90%; }
.font-80p { font-size: 80%; }

// Header
.page-title {
  font-family: 'HelveticaNeue-Roman';
  font-size: 17px;
  
}
@include media-breakpoint-up(sm) {
  .page-title {
    font-size: 1.5rem;
  }
}


.info-bar-link {
  font-family: 'HelveticaNeue-Bold';
  font-size: .75rem;
}
.info-bar-title {
  font-family: 'HelveticaNeue-Lite';
  font-size: 1.1rem;
}

//Modal
.modal-title {
  font-family: 'HelveticaNeue-Lite';
  font-size: 1.5rem;
  line-height: 1.2;
}

// Project list
.project-new-title {
  font-family: 'HelveticaNeue-Lite';
  font-size: 1.8rem;
}

//Project Details
.card-title {
  font-family: 'HelveticaNeue-Lite';
  font-size: 1.5rem;
}
.status-list-value {
  font-family: monospace;
  font-size: $font-size-sm;
}

//Action Center
.ac-title {
  font-family: 'HelveticaNeue-Bold';
  font-size: .8rem;
}
.ac-header-link {
  font-family: 'HelveticaNeue-Lite';
  font-size: .8rem;
  line-height: 1.1;
}
.ac-item-group-title {
  font-family: 'HelveticaNeue-Lite';
  font-size: 1rem;
}
.ac-item-title {
  font-family: 'HelveticaNeue-Lite';
  font-size: .9rem;
}
.ac-item-date {
  font-size: .6rem;
}

//Alert
.alert {
  font-size: .75rem;
  line-height: 2;
}
