@import '../../util.scss';
@import '../../variables.scss';

.attack-details-summary {
  .summary-col {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    
    &:first-child {
      margin-top: 30px;
      padding-bottom: 20px;
    }
    &:last-child {
      margin-bottom: 30px;
    }
  
    .summary-row-title {
      @include center-flex();
    }
  
    .summary-row {
      min-height: 30px;
      display: flex;
      align-items: center;
  
      &.border-top {
        border-top: 1px solid $alt-transparent;
        padding-top: 10px;
      }
      &.border-bottom {
        padding-bottom: 10px;
        border-bottom: 1px solid $alt-transparent;
      }
    }
  
    .back-icon-link {
      position: absolute;
      left: 2rem;
      
      .back-icon {
        margin: 0;
        font-size: 1rem;
        color: $brand-danger;
        opacity: .8;
      }
    }
    
    .spinner {
      width: 50px;
      margin-left: 1rem;
      text-align: center;
    }
  }
  
  .summary-icon {
    height: 60px;
    margin-left: -1rem;
  }
  
  .summary-title {
    margin: 0;
  }
  .summary-label {
    flex: none;
    width: 8rem;
    margin-right: .5rem;
    text-align: right;
    white-space: nowrap;
    color: $alt-transparent;
  }
  .summary-value {
    @include text-truncate();
  }
}

.attack-details-card {
  padding: 20px 40px 40px;
  color: $gray-light;
  
  //bs4
  .card-title {
    font-weight: normal;
    font-size: 1.5rem;
    color: $blue-darker;
  }
  
  .card-icon {
    height: 3rem;
    margin-right: 10px;
  }
  
  .card-row {
    min-height: 30px;
    display: flex;
    flex-wrap: wrap;
    
    &:last-child {
      padding-bottom: 20px;
      border-bottom: 1px solid $gray-lighter;
    }
    
    .card-label {
      flex: 0 0 150px;
      font-size: 90%;
      @include text-truncate();
    }
    .card-value {
      flex: 1 1 100%;
      margin-bottom: .5rem;
      color: $brand-danger;
    }
    .card-value-block {
      max-height: 500px;
      overflow-y: auto;
      flex: 1 0 100%;
      color: $gray-light;
      padding: .3rem .5rem;
      margin-top: .5rem;
      border-radius: 3px;
      box-shadow: inset 0 0 4px -1px rgba(0,0,0,.5);
      font-size: 90%;
      font-family: monospace;
    }
  }
}

@include media-breakpoint-up(lg) {
  .attack-details-summary {
    .summary-col {
      min-height: 90px;
      margin: 0 !important;
    
      .summary-row {
        min-height: 0;
        justify-content: flex-start;
        border: 0 !important;
        padding: 0 !important;
      }
    
      &:first-child {
        padding: 0;
      }
    }
  
    .summary-icon {
      height: 50px;
      margin-left: 1rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .attack-details-card {
    .card-row {
      flex-wrap: nowrap;
  
      .card-label {
        font-size: 100%;
      }
  
      .card-value {
        flex: 1 1 auto;
        margin-bottom: 0;
      }
    }
  }
}
