@import "../util.scss";
@import '../variables.scss';

.navbar {
  flex-direction: row; // bs4 override
  flex-wrap: nowrap; // bs4 override
  align-items: center;
  height: $navbar-h-xs;
  
  $brand-icon-h: 60px;
  $brand-icon-h-xs: 3rem;
  .brand-icon {
    height: $brand-icon-h-xs;
    padding: 10px 0;
    opacity: .9;
  }
  @include media-breakpoint-up(sm) {
    .brand-icon {
      height: $brand-icon-h;
    }
  }
  
  $no-menu-margin-left-xs: 1;
  $no-menu-margin-left: 2;
  .page-title {
    flex-grow: 1;
    margin: 0 1rem 0 #{$no-menu-margin-left-xs}rem;
    @include text-truncate();
    
    .sub-title {
      color: $alt-transparent;
    }
  }
  @include media-breakpoint-up(sm) {
    .page-title {
      margin: 0 1rem 0 #{$no-menu-margin-left}rem;
    }
  }
  
  //bs4
  $menu-toggler-w: 4.4; // magic num found by brute force
  $margin-sm: 1 - $no-menu-margin-left;
  $margin-md: (100% / 4); // 3 cols
  $margin-xl: (100% / 6); // 2 cols
  
  .navbar-toggler {
    font-size: 1.5rem !important;
    color: $white-base;
    margin-right: 0;
    align-self: center; // bs4 override
    
    &.has-actions {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 4px; left: 8px;
        height: 12px; width: 12px;
        border-radius: 50%;
        background: $brand-danger;
      }
    }
  }
  
  @include media-breakpoint-up(sm) {
    .navbar-toggler {
      margin-right: #{$margin-sm}rem;
    }
  }
  @include media-breakpoint-up(md) {
    .navbar-toggler {
      margin-right: calc(#{$margin-md} - #{$menu-toggler-w}rem);
    }
  }
  @include media-breakpoint-up(xl) {
    .navbar-toggler {
      margin-right: calc(#{$margin-xl} - #{$menu-toggler-w}rem);
    }
  }
}
@include media-breakpoint-up(sm) {
  .navbar {
    height: $navbar-h;
  }
}
