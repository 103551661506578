@import '../util.scss';
@import '../variables.scss';

.exclusion-form {
  
  .form-group {
    position: relative;
    
    .form-control-feedback {
      position: absolute;
      right: 0;
      top: 0;
      font-size: .8rem;
    }
  }
  
  .text-help {
    color: $brand-danger;
  }
}
