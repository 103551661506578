@import '../util.scss';
@import '../variables.scss';

.title-link {
  cursor: pointer;
  
  .fa {
    font-size: .8rem;
    margin-left: .5rem;
    opacity: .25;
    transition: opacity .2s ease;
  }
  @include media-breakpoint-up(sm) {
    .fa {
      font-size: 1rem;
    }
  }
  
  &:hover {
    .fa { opacity: .6; }
  }
}

.title-form {
  //bs4
  .form-group {
    position: relative;
    margin: 20px 0;
  
    .form-control {
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      background-color: $white-base;
      color: $blue-darker;
      font-size: 17px;
      padding: 3px 5px;
    }
    
    .invalid-feedback {
      display: block; // override bs4
      width: auto; // override bs4
      position: absolute;
      right: 0;
      bottom: -15px;
      font-size: .7rem;
      color: $brand-danger;
    }
  
    @include media-breakpoint-up(sm) {
      .form-control {
        font-size: 1.5rem;
        padding: .3rem;
      }
      .invalid-feedback {
        bottom: -20px;
        font-size: .8rem;
      }
    }
  
  }
}
