
.error-content {
  h1 {
    margin-top: 3rem;
    text-align: center;
    font-size: 2em;
    font-weight: 400;
    color: #fff;
  }
  p {
    text-align: center;
  }
}

@media only screen and (max-width: 280px) {
  .error-content {
    h1 {
      font-size: 1.5em;
    }
  }
}
