@import '../../util.scss';
@import '../../variables.scss';

.attack-list {
  position: relative;
  margin-bottom: 1rem;
  padding: 1rem;
  background: $widget-bg-dark;
  border-radius: 3px;
  box-shadow: 0 0 20px -1px rgba(0, 0, 0, 0.4);
  
  .attacks-list-empty {
    display: flex;
    justify-content: center;
  
    .spinner {
      display: inline-block;
    }
  }
  
  .table {
    background: $widget-bg-dark;
    
    &.loading {
      background: $widget-bg-alt;
    }
  
    tr {
      &:not(.disabled):hover {
        background: $widget-bg-active;
      }
      
      &.dimmed {
        opacity: .6;
      }
      
      &.placeholder {
        .bar {
          display: inline-block;
          height: 12px;
          background: #cecece57;
          animation: 2.5s linear 0s infinite alternate blink;
        }
      }
  
      &:not(.placeholder) {
        td {
          animation: .4s linear fadeIn;
        }
      }
    }
    
    th {
      background: $widget-bg-alt;
      color: #fff;
      
      &.severity,
      &.action,
      &.time {
        width: 10%;
      }
      &.device-id,
      &.type {
        width: 15%;
      }
      &.details {
        width: 40%;
      }
    }
    
    td {
      line-height: 1.4;
      border-top: 0;
      padding: .3rem .7rem .3rem .3rem;
      color: $white-base;
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0 }
  to { opacity: 1; }
}

@keyframes blink {
  0% { opacity: .5 }
  50% { opacity: 1; }
  100% { opacity: .5 }
}
