@import '../util.scss';
@import '../variables.scss';

.modal-download {
  .modal-title > .fa {
    font-size: 2rem;
  }
  
  .download-section {
    position: relative; /*for More link*/
    
    & ~ .download-section {
      margin-top: 1rem;
    }
    
    &.has-error {
      .download-section-title {
        color: #ccc;
      }
      
      $dl-error-bg: lighten($brand-danger, 45%);
      
      .download-body {
        background: $dl-error-bg;
      }
      
      .download-body,
      .download-body-icon {
        border-color: darken($dl-error-bg, 10%) !important;
      }
    }
    
    .link-show-more {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0;
    }
    
    .download-body {
      position: relative;
      padding: .25rem .5rem;
      font-family: monospace;
      font-size: 13px;
      border: 1px solid #ccc;
      border-radius: 3px;
      background: #f9f9f9;
      
      &.with-scroll {
        height: 202px;
        overflow-y: auto;
        padding: 0;
        
        .package-meta {
          padding: .25rem .5rem;
          
          &:hover {
            background: #d7d6d6;
            cursor: pointer;
          }
          
          &.selected {
            background: #3c3b3b;
            cursor: default;
          }
          
          &:not(:last-child) {
            border-bottom: 1px solid #ccc;
          }
        }
      }
      
      .download-body-icon {
        position: absolute;
        right: 0;
        top: 0;
        padding: 2px 2px 1px;
        font-size: 110%;
        background: #fff;
        border: 1px solid #ccc;
        border-top: 0;
        border-right: 0;
        border-radius: 0 3px 0 3px;
        color: $gray-dark;
      }
    }
    
    .download-record-group {
      position: relative;
      margin: 0 -3px;
      padding: 0 3px;
      outline: 1px #ccc solid;
    }

    .download-record {
      margin-top: 5px;
      
      & ~ .download-record {
        margin-bottom: 5px;
      }
    }
  }
  
  .btn-prompt-cont {
    position: absolute;
    right: 0;
    width: 0;
    top: 0;
    
    &.show-prompt {
      width: 100%;
    }
    
    .main-btn {
      position: absolute;
      top: 5px;
      right: 4px;
    }
  }
  
  .package-meta {
    min-height: 38px;
    
    h6 {
      margin-bottom: 0;
    }
  }
  
  .alert {
    margin-top: -1.5rem;
  }
  
  .hint-icon {
    color: $blue-darker;
    cursor: help;
  }
}
