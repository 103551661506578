@import '../../util.scss';
@import '../../variables.scss';

.file-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  
  .file-details-data {
    flex: auto;
    height: 100%;
    min-height: 0;
    padding: 1rem;

    display: flex;
    flex-direction: column;
  }
  @include media-breakpoint-up(lg) {
    .file-details-data {
      flex: none;
      height: auto;
    }
  }
  
  .file-details-actions {
    flex: none;
    align-self: flex-end;
    padding: 1rem;
  
    .btn:first-child {
      margin-right: 20px;
    }
  }
  
  .details-title {
    padding: 5px 0;
    border-bottom: 1px solid;
    font-size: 90%;
    color: $brand-danger;
  }
  
  .detail-item {
    flex: none;
    min-height: 1.5rem;
    display: flex;
    
    &:last-child {
      flex: auto;
    }
    
    $tile-col-h: 7rem;
    .detail-title {
      flex: none;
      width: $tile-col-h;
    }
    .detail-value {
      flex: none;
      width: calc(100% - #{$tile-col-h});
      word-wrap: break-word;
  
      &.with-list {
        overflow: auto;
      }
      @include media-breakpoint-up(lg) {
        &.with-list {
          min-height: 150px;
          max-height: calc(100vh - 500px);
        }
      }
  
      .twins-item {
        padding: 0 4px;
        margin: 1px; //for outline
    
        &.selected {
          outline: 1px dotted $alt-transparent;
        }
      }
    }
  }
}

