@import '../util.scss';
@import '../variables.scss';

.user-bar {
  border-left: 1px solid rgba(255, 255, 255, .4);
  
  // fix bs4
  .dropdown-menu {
    top: -2rem!important;
  }
  @include media-breakpoint-up(sm) {
    .dropdown-menu {
      top: -4.2rem!important;
    }
  }
  
  .nav-link {
    padding: 0.5rem 0;
  }
}

$circle-sz: 50px;
.user-logo {
  width: $circle-sz;
  height: $circle-sz;
  line-height: $circle-sz - 4px; // borders
  margin: 0 15px 0 30px;
  border: 2px solid;
  border-radius: 50%;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
  opacity: .6;
}
@include media-breakpoint-down(xs) {
  .user-logo {
    margin: 0 5px 0 20px;
  }
}

.user-menu {
  display: inline-flex !important; // overwrite bs4
  align-items: center;
  
  &:after {
    border-top-color: $brand-danger;
  }
}
