.attack-list-header {
  position: relative;
  margin-bottom: .5rem;
  display: flex;
  align-items: center;
  
  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -12px 0 0 -10px;
  }
  
  .btn-refresh {
    padding: 6px 20px;
    display: flex;
    
    &:focus {
      box-shadow: none;
    }
    
    .fa {
      font-size: 18px;
    }
  }
  
  .attack-list-pager {
    margin-left: auto;
  }
}
