
.back-icon {
  font-size: .5rem;
  margin: 0 5px 3px 0; // hack
}

.svg-icon {
  width: auto;
  &.sz-xs {
    height: 1.2rem;
  }
  &.sz-sm {
    height: 1.4rem;
  }
  &.sz-md {
    height: 2rem;
  }
  &.sz-lg {
    height: 3rem;
  }
  &.sz-xl {
    height: 4rem;
  }
  &.sz-xxl {
    height: 6rem;
  }
}
