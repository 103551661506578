@import '../util.scss';
@import '../variables.scss';

.exclusion-list {
  .table {
    td {
      &.td-target {
        width: 25%;
        
        .standout {
          margin-bottom: 4px;
          color: lighten($brand-danger, 10%);
          @include text-ellipsis();
        }
        .small {
          font-size: 80%;
          color: $gray-light;
        }
      }
      
      &.td-descr {
        div {
          @include text-ellipsis();
        }
      }
      
      &.td-edit {
        width: 2.5rem;
        
        &.disabled {
          cursor: not-allowed;
          color: $gray-light;
        }
        &:not(.disabled):hover {
          cursor: pointer;
        }
      }
    }
  }
  
  .exclusion-empty {
    background: $white-base;
    padding: 1px;
    
    & > p {
      margin: 3rem 1rem;
      padding: .5rem;
      border: 1px solid $gray-lighter;
      border-left: 0;
      border-right: 0;
      text-align: center;
      color: $brand-danger;
    }
  }
}
