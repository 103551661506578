/*
 * Colors
 * ========================================================================== */

$white-base: hsl(255, 255, 255);
$gray-light: #878788;
$gray-lighter: #e1e1e1;
$gray-transparent: rgba(0, 0, 0, 0.04);
$gray-dark: #333;

$blue-darker: #313443;
$blue-lighter: #ebe9f3;

$brand-primary: #1f2020;
$brand-danger: #ed1e63;
$card-cap-bg: lighten($brand-primary, 60%);
$side-menu-bg: #232628;
$action-center-bg: rgba(0, 0, 0, .9);
$main-bg: #262c2e;
$alert-bg-info: rgba(114, 126, 234, 0.96);
$alert-bg-warning: rgba(92, 32, 118, 0.94);
$warn-bg: #f1d449;
$form-border-color: #85829f;

$alt-color: #333;
$alt-color-lite: #555;
$alt-transparent: rgba(235, 255, 255, .6);
$alt-transparent-lite: rgba(235, 255, 255, .3);
$info-bar-bg: #232628;
$info-bar-color: #fff;
$card-disabled-bg: #e1dfec;
$warn-color: darken($brand-danger, 15%);

$widget-bg-dark: #0e0e0e;
$widget-bg-alt: #272c2e;
$widget-bg-active: #434a4e;

// bs4
$theme-colors: (
  "primary": #1f2020,
  "danger": #ed1e63,
  "success": $form-border-color,
  "info": $alert-bg-info,
  "warning": $alert-bg-warning,
);
$link-color: $brand-danger;
$link-hover-color: darken($link-color, 15%);
$tooltip-bg: $brand-danger;
$text-muted: $alt-transparent;
// bs4 forms
$input-bg: $main-bg;
$input-color: #fff;
$input-border-radius: 0;
$input-border-color: $form-border-color;
$input-focus-border-color: #66afe9;
$input-padding-x: .3125rem;
$btn-padding-y: .5rem;
$btn-padding-x: 1rem;
$btn-padding-y-lg: .75rem;
$btn-padding-x-lg: 1.5rem;
$btn-line-height-lg: 1.25;
//bs4 navbar
$navbar-dark-toggler-border-color: transparent;
// bs4 nav
$nav-pills-border-radius: 0;
$nav-link-padding-y: .8rem;
$nav-link-padding-x: 1rem;
//bas4 table
$table-cell-padding: .5rem .3rem;
$table-hover-bg: rgba(0,0,0,.05);
$table-border-color: $gray-lighter;
//bs4 modal
$modal-footer-border-width: 0;
$modal-md: 31.25rem; //500px
// bs4 alert
$alert-padding-y: .25rem;
$alert-bg-level: -1; //-10 !default;
$alert-border-level: -1; //-9 !default;
$alert-color-level: -9; //6 !default;
// bs4 custom forms
$custom-control-indicator-bg: $gray-lighter;
$custom-control-indicator-checked-bg: $brand-danger;
$custom-control-indicator-active-bg: $brand-danger;
$custom-control-indicator-disabled-bg: $gray-light;
$custom-control-indicator-focus-box-shadow: none;
$custom-control-indicator-active-box-shadow: none;
$custom-control-indicator-checked-box-shadow: none;

/*
 * Layout
 * ========================================================================== */

$navbar-h: 100px;
$navbar-h-xs: 70px;
$infobar-h: 48px;
$infobar-h-xs: 20px;
$top-h: $navbar-h + $infobar-h;
$top-h-xs: $navbar-h-xs + $infobar-h-xs;

/*
 * Typography
 * ========================================================================== */

// bs4
$font-family-base: 'Segoe UI', 'HelveticaNeue-Light', sans-serif;
