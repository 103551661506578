@import '../util.scss';
@import '../variables.scss';

.side-menu-container {
  .shadow {
    content: "";
    position: fixed;
    left: 0; right: 0;
    top: 0; bottom: 0;
    background: transparent;
    transition: background-color .3s;
    z-index: 1032;
    pointer-events: none;
  }
  &.open {
    .shadow {
      background: rgba(0, 0, 0, .5);
      pointer-events: auto;
    }
  }
}

.side-menu {
  position: fixed;
  left: 0;
  top: 0; bottom: 0;
  width: 100%;
  min-width: 220px; /*to fit the logo*/
  padding: 20px;
  padding-top: $top-h-xs;
  background: $side-menu-bg;
  z-index: 1033;
  transition: transform .3s;
  transform: translateX(-100%);
  
  .side-menu-container.open & {
    transform: translateX(0);
  }
  
  .brand-icon {
    position: absolute;
    top: 10px; left: 5px;
    height: 60px;
    width: auto;
    opacity: .5;
  }
  
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  
  .menu-chevron {
    float: right;
    font-size: .6rem;
    padding: .5rem 0 .5rem .5rem;
    line-height: 8px;

    &.lg {
      font-size: .8rem;
    }
  }
  
  .nav-main {
    height: 100%;
    margin-top: 0;
    margin-left: -20px;
    margin-right: -20px;
    overflow-x: hidden;
    overflow-y: auto;
    flex-wrap: nowrap; // override bs4
  
    // separator
    .nav-item {
      width: 100%; // Fix FF60+ bug
      .nav-link {
        position: relative;
      
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 1rem; right: 10px;
          height: 1px;
          background: $alt-transparent;
          opacity: .5;
        }

        &.nav-indent:after {
          left: 3rem
        }

        &.nav-bt:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0; right: 10px;
          height: 1px;
          background: $alt-transparent;
        }
        
        &.nav-em {
          font-style: italic;
          color: $brand-danger;
        }
      }
    }

    .nav-inner > .nav-item:last-child {
      .nav-link:after { display: none }
    }

    // custom scroll-bar: FF
    scrollbar-color: #c8c6c4 transparent;
    scrollbar-width: thin;

    // custom scroll-bar: Webkit
    &::-webkit-scrollbar {
      height: 18px;
      width: 18px;
    }
  
    &::-webkit-scrollbar-button {
      display: none;
    }
  
    &::-webkit-scrollbar-corner {
      cursor: pointer;
      background: transparent;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:hover {
      border-radius: 9px;
      border: solid 6px transparent;
      background-clip: content-box
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c8c6c4;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #98a3a6;
    }
    &::-webkit-scrollbar-thumb:vertical {
      min-height: 50px
    }
  }
  
  .nav-link {
    &:not(.disabled) {
      color: rgba(255, 255, 255, .5);
    }
    &.active {
      color: #fff;
      background-color: $brand-primary;
      
      .svg-icon {
        opacity: 1;
      }
    }
    &.nav-indent {
      padding-left: 3rem;
    }
  
    .svg-icon {
      vertical-align: top;
      margin-right: 10px;
      opacity: .7;
      
      // XXX - fix inconsistent icon size
      &.actions-icon {
        height: 1.8rem;
        margin: -3px 8px -3px -4px;
      }
    }
  }
}
@media (min-width: 300px) {
  .side-menu {
    width: 85%;
  }
}
@media (min-width: 375px) {
  .side-menu {
    width: 75%;
  }
}
@include media-breakpoint-up(sm) {
  .side-menu {
    width: 300px;
    padding-top: $top-h;
  }
}
@include media-breakpoint-up(md) {
  .side-menu {
    width: percentage(1/4);
  }
}
@include media-breakpoint-up(xl) {
  .side-menu {
    width: percentage(1/6);
  }
}

