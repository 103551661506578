@import '../../util.scss';
@import '../../variables.scss';

.auth-content {
  height: 100%;
  background-image: url('./images/bg-auth.jpg');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  
  .auth-form-col {
    height: 100%;
    padding: 3rem 2rem;
    background: $main-bg;
  }
  @include media-breakpoint-up(sm) {
    .auth-form-col {
      padding: 6rem 3rem;
    }
  }
  
  
  // bs4
  .form-group {
    position: relative;
    margin-bottom: 30px;
    
    .form-control {
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      box-shadow: none;
      
      &[disabled] {
        opacity: .7;
        background-color: rgba(255,255,255,.1);
      }
    }

    .invalid-feedback {
      position: absolute;
      width: auto; // override bs4
      right: 0;
      bottom: -20px;
      font-size: .8rem;
    }
  }
  
  .form-text {
    margin-top: 30px;
  }
  
  .server-error {
    visibility: hidden;
    margin: 40px 0 8px;
    
    &.visible {
      visibility: visible;
    }
  }
  
  .loader {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
  }
  
  .critical-error {
    margin: -3rem -2rem 2rem;
    padding: 1rem;
    background: $warn-bg;
    color: $main-bg;
    font-size: 80%;
  }
  @include media-breakpoint-up(sm) {
    .critical-error {
      margin: -6rem -3rem 2rem; // to compensate for AuthPath padding
    }
  }
  
  .version {
    position: absolute;
    bottom: 0; right: 0;
    padding: .5rem;
    font-size: 10px;
    color: $alt-transparent-lite;
  }
}
