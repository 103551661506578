@import '../../variables.scss';

.toggler {
  position: relative;
  height: 10px; width: 30px;
  margin: 5px;
  border-radius: 10px;
  background: $gray-lighter;
  
  .gripper {
    position: absolute;
    top: -4px; right: -5px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $brand-primary;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .2);
    transition: transform .3s ease, background-color .3s;
    
    &.checked {
      transform: translateX(-20px);
      background-color: $white-base;
    }
  }
}
