@import '../util.scss';
@import '../variables.scss';

.perf-bar {
  position: fixed;
  top: 0;
  left: 0; right: 0;
  height: 50px;
  background: #fff;
  color: #333;
  overflow: hidden;
  z-index: 99999;
  
  .close {
    padding: .5rem;
  }
  
  .perf-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: transform .3s ease;
    
    &.show-parts {
      transform: translateY(-50%);
    }
    
    & > * {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
    }
    
    .perf-parts {
      font-size: 80%;
      
      & > * {
        flex: 1 0 0;
      }
    }
    
    .perf-bullet {
      display: inline-block;
      width: 12px; height: 12px;
      border-radius: 50%;
      margin: 0 5px;
    }
  }
  
  .perf-footer {
    position: absolute;
    bottom: 0;
    left: 0; right: 0;
    height: 15px;
    background: #ccc;
    display: flex;
    align-items: stretch;
    cursor: pointer;
  }
}

